<template>
  <div class="dispark-bank-box">
    <top-home title="开放银行"></top-home>
    <div class="dispark-bank-img">
      <div class="img-box">
        <img v-if="nhdzzhData.cardNo" src="../../../assets/PayFee/agricultural-on.png" />
        <img v-else src="../../../assets/PayFee/agricultural-off.png" />
        <div class="img-text" v-if="nhdzzhData.cardNo">农行电子账户：{{nhdzzhData.cardNo}}</div>
        <div class="img-text" v-else>暂无农行电子账户</div>
        <div class="dk-box" v-if="!nhdzzhData.cardNo">
          <div class="dispark-bank-btn" @click="sqnhdkFun">申请开通农行电子账户</div>
        </div>
        <div class="dk-box">
          <div class="dispark-bank-btn" @click="bandFun">已有账户,绑定农行电子账户代扣</div>
          <div class="dispark-bank-radio">
            <van-checkbox v-model="consent"></van-checkbox>
            <div class="tip" @click="consent=!consent">我已阅读并同意</div>
          </div>
          <div class="fwxy-text" @click="isXy=true">《中国农业银行股份有限公司授权支付服务三方协议》</div>
        </div>
      </div>
    </div>
    <van-popup
      closeable
      v-model="isAgricultural"
      position="right"
      :style="{ width:'100%', height: '100%' }"
    >
      <div class="iframe-box">
        <div class="details-top">开通农行电子账户</div>
        <iframe class="iframe-view" ref="iframe" v-if="isAgricultural" :src="iframeViewUrl"></iframe>
      </div>
    </van-popup>
    <van-popup
      closeable
      v-model="isBand"
      position="right"
      :style="{ width:'100%', height: '100%' }"
    >
      <div class="iframe-box">
        <div class="details-top">绑定农行电子账户代扣</div>
        <iframe class="iframe-view" ref="iframe" v-if="isBand" :src="bandIframeViewUrl"></iframe>
      </div>
    </van-popup>
    <van-popup v-model="isXy" position="right" :style="{ width:'80%', height: '100%' }">
      <BankAccountAgree></BankAccountAgree>
    </van-popup>
  </div>
</template>

<script>
import { getOpenBankDataApi } from '@/reuqest/api.js'
import { mapActions, mapGetters } from 'vuex'
import BankAccountAgree from '@/components/CostManager/PayFee/BankAccountAgree.vue'
import config from '@/config'
export default {
  components: {
    BankAccountAgree,
  },
  data() {
    return {
      text: '',
      isAgricultural: false,
      isBand: false,
      code: 'wx_kfyh_kt',
      bandCode: 'wx_kfyh_bddk',
      consent: true,
      isXy: false,
      nhdzzhData: {},
    }
  },
  computed: {
    ...mapGetters(['bindList', 'token']),
    iframeViewUrl() {
      return `${config.PC_FORM_VISIT_URL}/${this.code}?token=${this.token}`
    },
    bandIframeViewUrl() {
      return `${config.PC_FORM_VISIT_URL}/${this.bandCode}?token=${this.token}`
    },
  },
  created() {
    let yhId = ''
    this.bindList.forEach((item) => {
      if (item.flag) {
        yhId = item.yhId
      }
    })
    getOpenBankDataApi({ yhId }).then((res) => {
      this.nhdzzhData = res.data
    })
  },
  mounted() {
    window.addEventListener('message', this.handleMessage, true)
  },
  destroyed() {
    window.removeEventListener('message', this.handleMessage, true)
  },
  methods: {
    ...mapActions(['getBindList']),
    handleMessage(event) {
      const data = event.data
      if (data.cmd == 'setHeight') {
        document.querySelector('.iframe-view').style.height =
          data.params.data.height + 'px'
      } else if (data.cmd == 'refresh') {
        //关闭弹窗重新 获取绑定列表
      } else if (data.cmd == 'message') {
        console.log('提示', data)
        if (data.params.type == '') {
          this.$toast(data.params.text)
        } else {
          this.$toast[data.params.type](data.params.text)
          if (data.params.submit) {
            this.isAgricultural = false
            this.isBand = false
            const loadingToast = this.$toast.loading({
              message: '加载中...',
              forbidClick: true,
              duration: 0,
            })
            this.getBindList().then(() => {
              loadingToast.clear()
              if (data.params.replace) {
                this.$router.replace(data.params.replace)
              }
            })
          }
        }
      }
    },
    sqnhdkFun() {
      this.isAgricultural = true
    },
    bandFun() {
      if (this.consent) {
        this.isBand = true
      } else {
        this.$toast('请阅读并同意支付服务协议')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.details-top {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  font-size: 16px;
  background-color: #fff;
  height: 30px;
  padding-top: 16px;
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
}
.dispark-bank-box {
  min-height: 100vh;
  width: 100%;
  padding: 49px 15px 10px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  .dispark-bank-img {
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
    .img-text {
      position: absolute;
      left: 20px;
      top: 20px;
      font-size: 16px;
      color: #fff;
    }
  }
  .dispark-bank-btn {
    font-size: 14px;
    color: #fff;
    background-color: #21a4fe;
    width: 90%;
    margin: 0 auto;
    border-radius: 30px;
    padding: 15px 0;
    margin-top: 10px;
  }
}
.iframe-box {
  width: 100%;
  padding-top: 47px;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
  .iframe-view {
    width: 100%;
    min-height: 100%;
    border: 0;
    overflow: hidden;
    box-sizing: border-box;
  }
}
.already-box {
  font-size: 16px;
  padding-top: 10px;
  color: #999;
}
.dispark-bank-radio {
  display: flex;
  font-size: 14px;
  padding-left: 5%;
  padding-top: 8px;
  align-items: center;
  .tip {
    padding-left: 5px;
    margin-top: -2px;
  }
  .van-checkbox {
    .van-checkbox__icon {
      font-size: 14px;
    }
  }
}
.fwxy-text {
  font-size: 12px;
  color: #76c4fa;
}
</style>